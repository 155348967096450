import styled from 'styled-components'

import { IconAppLogo } from 'ui-elements/Icons/IconAppLogo'
import { Typography } from 'ui-elements/Typography'

import { BackButton } from '../BackButton'

export const HeaderContainer = styled.header`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 12px 16px 8px 7px;
  flex-shrink: 0;
`

export const ProgressHeaderContainer = styled(HeaderContainer)`
  justify-content: space-between;
`

export const StyledAppLogo = styled(IconAppLogo)`
  margin-left: -20px;
`

export const FixedHeaderContainer = styled(HeaderContainer)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;
`

export const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: 7px;
  top: 4px;
  bottom: auto;
`

export const ProgressBackButton = styled(BackButton)`
  padding: 0 8px;
`

export const HeaderTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.252px;
`

export const PagesCounter = styled.div`
  display: flex;
  gap: 2px;
  margin: 0;
`
